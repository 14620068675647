<template>
<div class="workspace" onselectstart="return false">
  <div class="rlcanvas">
    <canvas :id="'tl' + type" class="scanvas tl" width='0' height='0'></canvas>
    <canvas :id="'marktl' + type" class="scanvas marktl" width='0' height='0'></canvas>
    <canvas :id="'pretl' + type" class="scanvas pretl" width='0' height='0' @mousedown="canvasclick" @mouseup="mouseup" @mousemove="move" @mousewheel="mousescale" @DOMMouseScroll="mousescale"></canvas>
    <div class="sbtns">
      <!-- 下方结果选择展示按钮群 -->
      <el-checkbox-group v-model="showclr">
        <el-checkbox v-for="(clr, ci) in curclrmap" :key="ci"
          :label="clr.ci">
          <div class="d-inline-flex">
            <div class="sbtns-icon" :style="markColors[clr.ci]"></div>
            <span>{{clr.name}}</span>
          </div>
        </el-checkbox>
      </el-checkbox-group>
      <!-- 信息 -->
      <div class="infos" v-if='segs.length > 0'>
        <p>IM：{{ segindex }}/{{ segs[segs.length-1].index }}</p>
        <p v-if="patientName">{{ patientName }}</p>
      </div>
    </div>
  </div>
  <!-- 滑块组 -->
  <div class="botbar">
    <div class="fullbtn" @click="segstepi(-1)">
      <icon-font
        name="#mdd-zuojiantou"
        :width="20"
        :height="20"
      ></icon-font>
    </div>
    <div class="track" @mouseup="lmbtnMove = false" @mouseleave="lmbtnMove = false" @mousemove="lmbtnMoveEvent">
      <div class='mbtn' :id="'lmbtn' + type" @mousedown="clickmbtn"></div>
    </div>
    <div class="fullbtn" @click="segstepi(1)">
      <icon-font
        name="#mdd-youjiantou"
        :width="20"
        :height="20"
      ></icon-font>
    </div>
    <div class="fullbtn" @click="full()" :style="style">
      <icon-font
        :name="isFull ? '#mdd-shituqiehuan' : '#mdd-shituqiehuan1'"
        :width="20"
        :height="20"
      ></icon-font>
    </div>
  </div>
</div>
</template>

<script>
import * as daikon from 'daikon'

// 解除浏览器右键事件
document.oncontextmenu = function (e) {
  e.cancelBubble = true
  return false
}

export default {
  name: 'app',
  props: {
    // 类型0：矢状位，1：冠状位
    type: Number,
    // 透明度
    opca: {
      type: Number,
      default: 0.5
    },
    datas: {
      type: Array,
      default: () => []
    },
    // 全屏按钮置灰
    fullDisable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isFull: true,
      loadall: false,
      patientName: '',
      lmbtnMove: false,
      rightdrag: false,
      leftdrag: false,
      tl: null,
      marktl: null,
      pretl: null,
      segs: [],
      si: -1,
      // 实际绘制宽高
      rate: 1,
      // 冠状位，矢状位的页定位
      segindex: 0,
      pos: null,
      dw: 0,
      dh: 0,
      // 1-14类标注色表
      clrmap: [],
      showclr: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      // 当前页面的展示
      curclrmap: []
    }
  },
  computed: {
    markColors () {
      return this.clrmap.map(
        clrItem => {
          const curRGB = clrItem.rgb
          return `background-color: rgb(${curRGB[0]},${curRGB[1]},${curRGB[2]})`
        }
      )
    },
    style () {
      const style = {}
      if (this.fullDisable) {
        style.borderColor = '#595B5E'
        style.color = '#595B5E'
      }
      return style
    }
  },
  mounted: function () {
    this.clrmap = [
      { ci: 0, name: '内侧半月板', rgb: [255, 0, 0] },
      { ci: 1, name: '内侧半月板前角', rgb: [0, 255, 0] },
      { ci: 2, name: '内侧半月板后角', rgb: [0, 0, 255] },
      { ci: 3, name: '外侧半月板', rgb: [255, 255, 0] },
      { ci: 4, name: '外侧半月板前角', rgb: [255, 0, 255] },
      { ci: 5, name: '外侧半月板后角', rgb: [0, 255, 255] },
      { ci: 6, name: '内侧副韧带', rgb: [128, 40, 0] },
      { ci: 7, name: '外侧副韧带', rgb: [0, 166, 255] },
      { ci: 8, name: '前交叉韧带', rgb: [255, 136, 0] },
      { ci: 9, name: '后交叉韧带', rgb: [128, 0, 255] },
      { ci: 10, name: '股骨', rgb: [41, 127, 1] },
      { ci: 11, name: '髌骨', rgb: [0, 38, 123] },
      { ci: 12, name: '胫骨', rgb: [0, 149, 2] },
      { ci: 13, name: '标注范围', rgb: [12, 12, 25] }
    ]
    this.tl = document.getElementById('tl' + this.type)
    this.marktl = document.getElementById('marktl' + this.type)
    this.pretl = document.getElementById('pretl' + this.type)

    // 添加画布绘制的变换参数
    this.pretl.ctxleft = 0
    this.pretl.ctxtop = 0
    this.pretl.ctxscale = 1
    this.pretl.cflag = 'left'
    this.init()
    this.load()
  },
  watch: {
    loadall: function () {
      if (this.loadall) {
        // 加载完成
        this.si = 0
      }
    },
    opca: function () {
      this.drawlr()
    },
    si: function () {
      // if (this.showclr.length < 13) this.showclr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      this.curclrmap = []
      this.showclr = []
      this.segs[this.si].mark.forEach((mark, mi) => {
        if (mark !== '' && mi !== 13) {
          this.curclrmap.push(this.clrmap[mi])
          this.showclr.push(mi)
        }
      })
      this.drawlr()
      const lmbtn = document.getElementById('lmbtn' + this.type)
      if (!this.lmbtnMove && this.segs.length > 0) {
        const ww = lmbtn.parentNode.clientWidth
        lmbtn.style.left = Math.round(this.si * (ww - 24) / (this.segs.length - 1)) + 'px'
      }
    },
    showclr: function () {
      this.drawlr()
    }
  },
  methods: {
    clickmbtn (e) {
      this.dx = this.GetClickInfo(e.target, e.clientX, e.clientY).clickX
      this.lmbtnMove = true
    },
    lmbtnMoveEvent (e) {
      if (this.lmbtnMove) {
        e = e || window.event
        const lmbtn = document.getElementById('lmbtn' + this.type)
        const track = lmbtn.parentNode
        const x = this.GetClickInfo(e.target.parentNode, e.clientX, e.clientY).clickX
        const w = track.offsetWidth
        let di = Math.round(x / w * (this.segs.length - 1))
        if (di < 0) {
          di = 0
        }
        this.si = di
        if (this.segs.length > 0) {
          this.segindex = this.segs[this.si].index
          let sleft = x - this.dx
          if (sleft < 0) {
            sleft = 0
          }
          if (sleft > track.clientWidth - 24) {
            sleft = track.clientWidth - 24
          }
          lmbtn.style.left = sleft + 'px'
        }
      }
    },
    init: function () {
      const pldom = this.tl.parentNode
      this.tl.width = pldom.clientWidth
      this.tl.height = pldom.clientHeight
      this.marktl.width = pldom.clientWidth
      this.marktl.height = pldom.clientHeight
      this.pretl.width = pldom.clientWidth
      this.pretl.height = pldom.clientHeight
    },
    initdraw: function (w, h, w0, h0, cvs) {
      if (w0 / w > h0 / h) {
        cvs.ctxscale = w / w0
        h0 = h0 * w / w0
        cvs.ctxtop = (h - h0) / 2
      } else {
        cvs.ctxscale = h / h0
        w0 = w0 * h / h0
        cvs.ctxleft = (w - w0) / 2
      }
    },
    full () {
      if (this.fullDisable) return
      this.isFull = !this.isFull
      console.log(this.isFull)
      this.$emit('fullEvent', this.type)
    },
    segstepi: function (i) {
      this.si += i
      if (this.si < 0) {
        this.si = 0
      }
      if (this.si > this.segs.length - 1) {
        this.si = this.segs.length - 1
      }
      this.segindex = this.segs[this.si].index
    },
    getfile: function (url) {
      return new Promise(function (resolve, reject) {
        const http = new XMLHttpRequest()
        http.open('Get', url, true)
        http.responseType = 'arraybuffer'
        http.onload = function () {
          resolve(http.response)
        }
        http.onerr = function (e) {
          reject(e)
        }
        http.send()
      })
    },
    reloadsi: function () {
      const w = this.tl.width
      const h = this.tl.height
      this.initdraw(w, h, this.dw, this.dh, this.pretl)
      this.curclrmap = []
      this.showclr = []
      this.segs[this.si].mark.forEach((mark, mi) => {
        if (mark !== '' && mi !== 13) {
          this.curclrmap.push(this.clrmap[mi])
          this.showclr.push(mi)
        }
      })
      this.drawlr()
      const lmbtn = document.getElementById('lmbtn' + this.type)
      if (!this.lmbtnMove && this.segs.length > 0) {
        const ww = lmbtn.parentNode.clientWidth
        lmbtn.style.left = Math.round(this.si * (ww - 24) / (this.segs.length - 1)) + 'px'
      }
    },
    load: function () {
      let initlflag = true
      const datas = this.datas
      let loadnum = 0
      this.segs = []
      for (let i = 0; i < datas.length; i++) {
        const curDcm = datas[i]
        const src = curDcm.url
        this.getfile(src).then(rsp => {
          const parser = new daikon.Parser()
          const data = new DataView(rsp)
          const img = parser.parse(data)
          // const imgdirc = img.getAcquiredSliceDirection()
          // 初始绘制，设置绘制位置居中
          if (initlflag) {
            initlflag = false
            const w0 = img.getCols()
            const h0 = img.getRows()
            const w = this.tl.width
            const h = this.tl.height
            this.initdraw(w, h, w0, h0, this.pretl)
          }
          this.segs.push({
            index: img.getImageNumber(),
            img: this.parsedcm(img),
            mark: curDcm.mark
          })
          this.segs.sort((a, b) => {
            return a.index - b.index
          })
          loadnum += 1
          if (loadnum > datas.length - 1) {
            this.loadall = true
          }
        })
      }
    },
    // ct值查找表
    clrlut: function (max, min, wc, ww) {
      const lut = []
      const wl = Math.round(wc - ww / 2)
      const wr = Math.round(wc + ww / 2)
      for (let i = min; i < max; i++) {
        let l
        if (i < wl) {
          l = 0
        }
        if (i > wr) {
          l = 255
        }
        l = Math.round((i - min) / ww * 255)
        lut.push(l)
      }
      return lut
    },
    getgray: function (p, wc, ww) {
      const wl = wc - ww / 2
      const wr = wc + ww / 2
      if (p < wl) {
        return 0
      } else if (p > wr) {
        return 255
      } else {
        return Math.round(255 * (p - wl) / ww)
      }
    },
    parserlestr: function (rlestr) {
      // rlestr解压：https://github.com/cocodataset/cocoapi/blob/master/common/maskApi.c
      const cnt = []
      if (rlestr === '') {
        return cnt
      }
      for (let i = 0; i < rlestr.length;) {
        let x = 0
        let ti = 0
        let more = 1

        while (more) {
          const c = rlestr.charCodeAt(i++) - 48
          x |= (c & 0x1f) << 5 * ti++
          more = c & 0x20
          if (more === 0 && (c & 0x10)) {
            x |= -1 << 5 * ti
          }
        }
        if (cnt.length > 2) {
          x += cnt[cnt.length - 2]
        }
        cnt.push(x)
      }
      return cnt
    },
    parsemark: function (countarr) {
      const rlecnts = []
      countarr.forEach(cstr => {
        rlecnts.push(this.parserlestr(cstr))
      })
      const w = this.dw
      const h = this.dh
      const precanvas = document.createElement('canvas')
      precanvas.width = w
      precanvas.height = h
      const prectx = precanvas.getContext('2d')
      const preimg = prectx.createImageData(w, h)

      for (let mi = 0; mi < rlecnts.length; mi++) {
        // 不展示指定位置标注
        if (this.showclr.indexOf(mi) < 0) {
          continue
        }
        const rlecnt = rlecnts[mi]
        let k = 0
        for (let i = 0; i < rlecnt.length; i++) {
          // 偶数位0 奇数位1
          const r = this.clrmap[mi].rgb[0]
          const g = this.clrmap[mi].rgb[1]
          const b = this.clrmap[mi].rgb[2]
          if (i % 2 === 0) {
            k += rlecnt[i]
            continue
          }
          for (let tmp = 0; tmp < rlecnt[i]; tmp++) {
            const x = Math.ceil(k / h)
            const y = k % h
            const xy = y * w + x
            preimg.data[4 * xy] = r
            preimg.data[4 * xy + 1] = g
            preimg.data[4 * xy + 2] = b
            preimg.data[4 * xy + 3] = Math.round(255 * this.opca)
            k++
          }
        }
      }
      // putImageData,不受画布变换影响，transform(0,1,1,0,0,0)无效
      prectx.putImageData(preimg, 0, 0, 0, 0, w, h)
      return precanvas
    },
    parsedcm: function (dcmimg) {
      const imgdata = dcmimg.getRawData()
      /**********************************
          dcm解析中用到的一些参数与函数
      ***********************************/
      // 可用作序列唯一标识
      // let suid = dcmimg.getSeriesInstanceUID()
      // 切片序列的信息：eg: 't1_fse_sag'
      // let sdec = dcmimg.getSeriesDescription()
      // getImageNumber: 获取图片在序列里的编号，getSeriesNumber：序列的编号，getNumberOfFrames：一张图片可能是多帧
      // getAcquiredSliceDirection: 获取图像方位：0:sag 1:cor 2: axial 3: oblique -1:unknown
      // getDatatype: 获取像素数据存储格式： 0:unknown 1:binary 2:int 3:uint 4:float 5:complex 6:RGB
      const w = dcmimg.getCols()
      const h = dcmimg.getRows()
      this.dw = w
      this.dh = h
      // 病人姓名
      if (!this.patientName) this.patientName = dcmimg.getPatientName()
      const wc = dcmimg.getWindowCenter()
      const ww = dcmimg.getWindowWidth()
      let pmax = dcmimg.getImageMax()
      let pmin = dcmimg.getImageMin()
      if (!pmin || !pmax) {
        pmin = 0
        pmax = 4096
      }

      const precanvas = document.createElement('canvas')
      precanvas.width = w
      precanvas.height = h
      const prectx = precanvas.getContext('2d')
      const pix = new Int16Array(imgdata)
      // const pix = (dcmimg.getInterpretedData(true, false))
      const preimg = prectx.createImageData(w, h)
      const lut = this.clrlut(pmax, pmin, wc, ww)
      for (let i = 0; i < h; i++) {
        for (let j = 0; j < w; j++) {
          // 整数存储
          const gray = lut[pix[w * i + j] - pmin]
          // let gray = this.getgray(pix[w * i + j], wc, ww)
          preimg.data[4 * (i * w + j)] = gray
          preimg.data[4 * (i * w + j) + 1] = gray
          preimg.data[4 * (i * w + j) + 2] = gray
          preimg.data[4 * (i * w + j) + 3] = 255
        }
      }
      // this.ctx1.putImageData(preimg, 0, 0, 0, 0, w, h)
      // return preimg
      prectx.putImageData(preimg, 0, 0, 0, 0, w, h)
      return precanvas
    },
    clearcanvas: function (cvs) {
      const curWidth = cvs.width
      cvs.width = curWidth
    },
    drawimg: function (cvs, img, left, top, scale) {
      const curWidth = cvs.width
      cvs.width = curWidth
      if (img === undefined || img === null) {
        return
      }
      const ctx = cvs.getContext('2d')
      ctx.save()
      ctx.translate(left, top)
      ctx.scale(scale, scale)
      ctx.drawImage(img, 0, 0, img.width, img.height)
      ctx.restore()
      // ctx.drawImage(img, 0, 0, img.width, img.height, left, top, img.width * scale, img.height * scale)
    },
    drawlr: function () {
      this.drawimg(this.tl, this.segs[this.si].img, this.pretl.ctxleft, this.pretl.ctxtop, this.pretl.ctxscale)
      // 生成标注图
      if (!this.segs[this.si].mark) {
        const md = this.marktl.width
        this.marktl.width = md
        return
      }
      const mimg = this.parsemark(this.segs[this.si].mark)
      this.drawimg(this.marktl, mimg, this.pretl.ctxleft, this.pretl.ctxtop, this.pretl.ctxscale)
    },
    drawcrossline: function (cvs, x, y) {
      const ctx = cvs.getContext('2d')
      const curWidth = cvs.width
      cvs.width = curWidth
      ctx.save()
      ctx.beginPath()
      ctx.strokeStyle = '#FF0000'
      ctx.setLineDash([2, 2])
      ctx.moveTo(x, 0)
      ctx.lineTo(x, cvs.height)
      ctx.stroke()
      ctx.moveTo(0, y)
      ctx.lineTo(cvs.width, y)
      ctx.stroke()
      ctx.restore()
    },
    GetTop: function (ele) {
      let offset = ele.offsetTop
      if (ele.offsetParent !== null) {
        offset += this.GetTop(ele.offsetParent)
      }
      return offset
    },
    GetLeft: function (ele) {
      let offset = ele.offsetLeft
      if (ele.offsetParent !== null) {
        offset += this.GetLeft(ele.offsetParent)
      }
      return offset
    },
    GetClickInfo: function (ele, x, y) {
      const clickX = x - this.GetLeft(ele)
      const clickY = y - this.GetTop(ele)
      const moveclickX = (clickX - ele.ctxleft) / ele.ctxscale
      const moveclickY = (clickY - ele.ctxtop) / ele.ctxscale

      return {
        clickX: clickX,
        clickY: clickY,
        moveclickX: moveclickX,
        moveclickY: moveclickY
      }
    },
    canvasclick: function (e) {
      this.pos = this.GetClickInfo(e.target, e.clientX, e.clientY)
      // 右键移动
      const eclick = e.keyCode || e.which || e.charCode
      if (eclick === 3) {
        this.rightdrag = true
      } else {
        this.leftdrag = true
      }
      // this.drawcrossline(e.target, this.pos.clickX, this.pos.clickY)
    },
    mouseup: function (e) {
      this.rightdrag = false
      this.leftdrag = false
    },
    move: function (e) {
      e = e || window.event
      const clickpos = this.GetClickInfo(e.target, e.clientX, e.clientY)
      if (this.rightdrag) {
        const dleft = clickpos.clickX - this.pos.clickX
        const dtop = clickpos.clickY - this.pos.clickY
        e.target.ctxleft += dleft
        e.target.ctxtop += dtop
        this.drawlr()
        // this.drawcrossline(e.target, clickpos.clickX, clickpos.clickY)
      }
      if (this.leftdrag) {
        // this.drawcrossline(e.target, clickpos.clickX, clickpos.clickY)
      }
      this.pos = clickpos
    },
    mousescale: function (e) {
      e = e || window.event
      this.pos = this.GetClickInfo(e.target, e.clientX, e.clientY)
      const ewheelDelta = e.wheelDelta || e.detail * (-40)
      if (!e.altKey) {
        const di = -ewheelDelta / 120
        this.segstepi(di)
        return
      }
      const delta = 1 + ewheelDelta / 1200
      // 最小缩放限制
      if (e.target.ctxscale * delta < 0.2) {
        return
      }
      e.target.ctxleft -= (this.pos.clickX - e.target.ctxleft) * (delta - 1)
      e.target.ctxtop -= (this.pos.clickY - e.target.ctxtop) * (delta - 1)
      e.target.ctxscale = e.target.ctxscale * delta
      // 绘制
      this.drawlr(e.target.cflag)
    }

  }
}

</script>

<style lang="less" scoped>
.cc {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
}
.workspace {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  border: 1px solid #383838;
}
.rlcanvas{
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  flex: 1;
}
.tophd {
  width: 100%;
  height: 10%;
  display: flex;
}
.botbar {
  display: flex;
  height: 24px;
  width: 100%;
  background-color: #303137;
  .track {
    position: relative;
    flex: 1;
  }
}
.barbtn {
  height: 100%;
  width: 24px;
  font-size: 20px;
  border: 1px solid #707070;
}
.fullbtn {
  width: 24px;
  height: 100%;
  line-height: 100%;
  text-align: center;
  border: 1px solid #707070;
  color: #ACACAF;
}
.mbtn {
  width: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ABADB0;
}
.sbtns {
  display: flex;
  width: 100%;
  height: 150px;
  padding: 0 20px;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 100;
  .infos {
    padding-bottom: 20px;
    align-self: flex-end;
    text-align: right;
    font-size: 12px;
    color: #A5A7AA;
    p {
      margin-bottom: 8px;
    }
  }
  .sbtns-icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    border: 1px solid #ABADB0;
  }
  /deep/.el-checkbox__label {
    padding-left: 4px;
    line-height: 18px;
    font-size: 12px;
    vertical-align: middle;
  }
  /deep/.el-checkbox {
    color: #fff;
    font-weight: 300;
  }
  /deep/.el-checkbox-group {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-end;
    flex: 1;
    padding-bottom: 20px;
  }
  /deep/.el-checkbox__input {
    line-height: inherit;
    &.is-checked+.el-checkbox__label {
      color: #fff;
    }
  }
  /deep/.el-checkbox__inner {
    width: 18px;
    height: 18px;
    background-color: rgba(0,0,0,0);
    border-color: #ABADB0;
    border-radius: 0;
    &::after {
      width: 5px;
      height: 9px;
      left: 5px;
      border-color: #ABADB0;
    }
  }
}
.scanvas {
  position: absolute;
  height: 100%;
  width: 100%;
}
.tl {
  background-color: #535353;
  z-index: 1;
}
.marktl {
  z-index: 2;
}
.pretl {
  z-index: 3;
}
</style>
