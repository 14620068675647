<template>
  <div class="analysis">
    <Header></Header>
    <div class="analysis-intro" v-if="guideElement">
      <div class="screen" :data-step="1" data-intro="上传的影像和标注结果在这里显示，可以使用鼠标滚轮切换图片，ctrl+滚轮放大/缩小图片，鼠标右键点击拖拽查看。"></div>
      <div class="track" :data-step="2" data-intro="单击该区域的左右箭头，可逐帧查看前后影像；拖动滑块可快速查看视图前后影像信息。"></div>
      <div class="full-btn" :data-step="3" data-intro="双视图模式下点击此处可将对应视图切换成单视图模式，再次点击返回。"></div>
      <div class="slider" :data-step="4" data-intro="拖动这里的滑块可以调整标注层的透明度。"></div>
      <div class="submit" :data-step="5" data-intro="点击此按钮选择本地文件并重新上传你需要分析的影像数据。"></div>
      <div class="btns" :data-step="6" data-intro="这里可以勾选查看/取消查看对应的标注部位。"></div>
    </div>
    <main>
      <AnalysisCanvas
        v-if="coronalFinish"
        ref="left"
        class="left"
        v-show="leftShow"
        :datas="coronalData"
        :type="1"
        :opca="canvasOpca"
        :full-disable="fullDisable"
        @fullEvent="fullEvent"
      />
      <AnalysisCanvas
        v-if="sagittalFinish"
        ref="right"
        class="right"
        v-show="rightShow"
        :datas="sagittalData"
        :type="0"
        :opca="canvasOpca"
        :full-disable="fullDisable"
        @fullEvent="fullEvent"
      />
    </main>
    <!-- footer -->
    <footer class="footer">
      <!-- tools -->
      <div class="tools">
        <div class="tool-box">
          <label>标注层透明度</label>
          <el-slider
            v-model="transparency"
            :max="100"
            :step="10"
            :format-tooltip="e => e + '%'"
            style="width: 160px;">
          </el-slider>
        </div>
      </div>
      <!-- button -->
      <el-button class="main-white" type="text" @click="showGuide">
        <icon-font
          class="mr-2"
          name="#mdd-chakan"
          :width="21"
          :height="18"
        ></icon-font>
        新手引导
      </el-button>
      <el-button class="reupload" type="primary" @click="exit">重新上传</el-button>
    </footer>
  </div>
</template>

<script>
import Header from '../components/KneeHeader.vue'
import AnalysisCanvas from '../components/AnalysisCanvas'
import { getAnalyseResult, getInfo, guide } from '@/api/modules/knee-system'
import intro from '@/utils/intro'
export default {
  name: 'analysis',
  components: {
    Header,
    AnalysisCanvas
  },
  data () {
    return {
      transparency: 50,
      leftShow: true,
      rightShow: true,
      coronalData: [],
      sagittalData: [],
      coronalFinish: false,
      sagittalFinish: false,
      guideElement: false // 用于新手引导的元素
    }
  },
  computed: {
    canvasOpca () {
      return this.transparency / 100
    },
    fullDisable () {
      return !this.coronalFinish || !this.sagittalFinish
    }
  },
  mounted () {
    const id = this.$route.query.id
    if (!id) return
    this.getUserInfo()
    getAnalyseResult(id).then(
      res => {
        const { data } = res
        data.forEach(dcm => {
          const markArray = Object.values(dcm.Mark)
          const mark = markArray.map(v => v.Counts)
          const newDcm = { url: dcm.Url, mark }
          if (dcm.Class === 'coronal') {
            this.coronalData.push(newDcm)
          }
          if (dcm.Class === 'sagittal') {
            this.sagittalData.push(newDcm)
          }
        })
        if (this.coronalData.length > 0) this.coronalFinish = true
        if (this.sagittalData.length > 0) this.sagittalFinish = true
      }
    )
  },
  methods: {
    fullEvent (type) {
      let e
      if (type === 1) {
        e = 'left'
        const curState = this.rightShow
        this.rightShow = !curState
      } else {
        e = 'right'
        const curState = this.leftShow
        this.leftShow = !curState
      }
      this.$nextTick(() => {
        this.$refs[e].init()
        this.$refs[e].reloadsi(this.$refs[e].segindex)
      })
    },
    exit () {
      this.$confirm(`
      <i class="el-icon-warning-outline" style="color: #EF9723;"></i>
      离开当前页面？<br/>
      离开后当前数据将无法继续查看`,
      '', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        center: true,
        showClose: false,
        dangerouslyUseHTMLString: true,
        customClass: 'analysis-message'
      }).then(() => {
        this.$router.push('/index')
      })
    },
    async getUserInfo () {
      const { data } = await getInfo()
      if (data.resultGuide) {
        this.showGuide()
      }
    },
    showGuide () {
      this.guideElement = true
      this.$nextTick(() => {
        intro.onexit(() => {
          // 点击结束按钮后执行的事件
          guide().then(res => {})
          this.guideElement = false
        }).start()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.analysis {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  main {
    display: flex;
    flex: 1;
    padding-top: 48px;
    background-color: #535353;
    .left {
      flex: 1;
    }
    .right {
      flex: 1;
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 50px;
    font-size: 14px;
    color: #fff;
    background-color: #383838;
    .tools {
      display: flex;
      flex: 1;
      padding: 0 20px;
      .tool-box {
        display: flex;
        align-items: center;
        margin-right: 60px;
        label {
          margin-right: 12px;
        }
      }
    }
    .reupload {
      width: 104px;
      height: 100%;
    }
    /deep/.el-slider__runway {
      background-color: #727478;
    }
    /deep/.el-slider__bar {
      background-color: #2B7FE2;
    }
    /deep/.el-slider__button {
      background-color: #2B7FE2;
      border-color: #d6d6d6;
    }
  }
}
.analysis-intro {
  position: absolute;
  top: 55px;
  left: 5px;
  right: 5px;
  bottom: 0;
  z-index: 1000;
  overflow: hidden;
  .screen {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 81px;
    width: 100%;
    // height: 150px;
  }
  .btns {
    position: absolute;
    left: 0;
    bottom: 81px;
    width: 100%;
    height: 150px;
  }
  .track {
    position: absolute;
    left: 0;
    bottom: 55px;
    width: 100%;
    height: 16px;
  }
  .full-btn {
    position: absolute;
    right: 0;
    bottom: 55px;
    width: 16px;
    height: 16px;
  }
  .slider {
    position: absolute;
    left: 15px;
    bottom: 5px;
    width: 250px;
    height: 38px;
  }
  .submit {
    position: absolute;
    right: 0;
    bottom: 5px;
    width: 92px;
    height: 38px;
  }
}
</style>
<style lang="less">
.analysis-message {
  width: 350px;
  .el-message-box__header {
    padding-top: 20px;
  }
  .el-message-box__btns {
    padding: 15px 15px 0;
  }
  .el-button {
    padding: 8px 32px;
    &:first-child {
      &:hover {
        color: #2B7FE2;
        border-color: #2B7FE2;
        background-color: #fff;
      }
    }
  }
}
</style>
